<template>
  <footer>Powered by
    <a target="_blank" href="https://github.com/TwiN/gatus">Gatus</a>
    and
    <a target="_blank" href="https://github.com/BluemediaGER/fancy-gatus">Fancy Gatus</a>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 14px;
}
a {
  color: #111;
  text-decoration: underline;
}
</style>